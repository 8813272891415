<!-- Using Java Script ECMAScript 2021 -->
<template>
  <div>
    <!-- check box -->
    <v-checkbox
      v-show="showCheckBox"
      :label="fieldLabel"
      v-model="fieldValue"
      dense
    >
      <template v-slot:append v-if="visibleInclude || removable">
        <!-- Include field in search results -->
        <include-field-button
          v-if="visibleInclude"
          :field="field"
          :disabled="disabledInclude"
        >
        </include-field-button>

        <!-- remove field button -->
        <remove-field-button
          v-if="removable"
          :field="field"
          @click="removeField"
        ></remove-field-button>
      </template>
    </v-checkbox>

    <!-- text field-->
    <v-text-field
      clearable
      dense
      v-show="showTextBox"
      :label="fieldLabel"
      :type="textFieldType"
      v-model="fieldValue"
    >
      <!-- prepend -->
      <template v-slot:prepend>
        <v-menu bottom left rounded :offset-x="false" :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small depressed v-bind="attrs" v-on="on">
              <v-icon small>{{ selectedOperatorIcon }}</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item-group v-model="selectedOperatorIndex">
              <v-list-item
                :color="menuItemColorClass"
                v-for="operator in operators"
                :key="operator.operator"
              >
                <v-list-item-icon
                  ><v-icon small>{{
                    iconOperator(operator.operator)
                  }}</v-icon></v-list-item-icon
                >
                <v-list-item-title>{{
                  operator.description
                }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>

      <!-- append -->
      <template v-slot:append-outer v-if="visibleInclude || removable">
        <!-- Include field in search results -->
        <include-field-button
          v-if="visibleInclude"
          :field="field"
          :disabled="disabledInclude"
        >
        </include-field-button>
        <!-- remove field button -->
        <remove-field-button
          v-if="removable"
          :field="field"
          @click="removeField"
        ></remove-field-button>
      </template>
    </v-text-field>

    <!--  Lookup Field  -->
    <v-autocomplete
      v-show="showLookup"
      v-model="fieldValue"
      :label="fieldLabel"
      :items="lookupItems"
      :loading="isLoadingLookup"
      :clearable="!lookupEnforce"
      :error-messages="lookupError"
      item-text="id"
      item-value="value"
      color="primary"
      dense
    >
      <!-- prepend -->
      <template v-slot:prepend>
        <v-menu bottom left rounded :offset-x="false" :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small depressed v-bind="attrs" v-on="on">
              <v-icon small>{{ selectedOperatorIcon }}</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item-group v-model="selectedOperatorIndex">
              <v-list-item
                :color="menuItemColorClass"
                v-for="operator in operators"
                :key="operator.operator"
              >
                <v-list-item-icon
                  ><v-icon small>{{
                    iconOperator(operator.operator)
                  }}</v-icon></v-list-item-icon
                >
                <v-list-item-title>{{
                  operator.description
                }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>

      <!-- append -->
      <template v-slot:append-outer v-if="visibleInclude || removable">
        <!-- Include field in search results -->
        <include-field-button
          v-if="visibleInclude"
          :field="field"
          :disabled="disabledInclude"
        >
        </include-field-button>
        <!-- remove field button -->
        <remove-field-button
          v-if="removable"
          :field="field"
          @click="removeField"
        ></remove-field-button>
      </template>
    </v-autocomplete>

    <!-- Text area -->
    <v-text-field
      outlined
      clearable
      dense
      :disabled="disabledUniversalSearch"
      v-show="showTextarea"
      :label="fieldLabel"
      v-model="fieldValue"
    >
      <template v-slot:append-outer>
        <remove-field-button
          v-if="removable"
          :field="field"
          @click="removeField"
        ></remove-field-button>

        <base-tooltip-icon
          v-if="disabledUniversalSearch"
          top
          :icon-name="iconWarning"
          :icon-color-class="iconColor"
        >
          {{ disabledUniversalSearchText }}
        </base-tooltip-icon>
      </template>
    </v-text-field>

    <!-- Date Picker -->
    <div v-show="showDatePicker">
      <date-picker
        :item="field"
        :default-current-date="false"
        :readonly="false"
        :disabled="false"
        :fieldLabel="fieldLabel"
        :fieldName="dateFieldValue"
        :clearable="true"
      >
        <!-- prepend -->
        <template v-slot:prepend>
          <v-menu bottom left rounded :offset-x="false" :offset-y="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab small depressed v-bind="attrs" v-on="on">
                <v-icon small>{{ selectedOperatorIcon }}</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item-group v-model="selectedOperatorIndex">
                <v-list-item
                  :color="menuItemColorClass"
                  v-for="operator in operators"
                  :key="operator.operator"
                >
                  <v-list-item-icon
                    ><v-icon small>{{
                      iconOperator(operator.operator)
                    }}</v-icon></v-list-item-icon
                  >
                  <v-list-item-title>{{
                    operator.description
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>

        <!-- append -->
        <template v-slot:append-outer v-if="visibleInclude || removable">
          <!-- Include field in search results -->
          <include-field-button
            v-if="visibleInclude"
            :field="field"
            :disabled="disabledInclude"
          >
          </include-field-button>

          <!-- remove field button -->
          <remove-field-button
            v-if="removable"
            :field="field"
            @click="removeField"
          ></remove-field-button>
        </template>
      </date-picker>
      <v-spacer></v-spacer>
    </div>
  </div>
</template>
<script>
// model
import {
  fieldType,
  searchOperator,
  searchOperators
} from "@/model/field/fieldModel";
import {
  enforceLookup,
  lookupItemCount,
  lookupItems
} from "@/model/field/formFieldModel";

// design
import {
  iconEqual,
  iconGreaterThan,
  iconGreaterThanOrEqual,
  iconLessThan,
  iconLessThanOrEqual,
  iconAsterisk,
  iconTrash,
  iconMinus,
  iconCalendar,
  iconCheckbox,
  iconCheckboxBlank,
  iconQuestion,
  iconDateOn,
  iconWarning
} from "@/design/icon/iconConst";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { deleteSettingsMixin } from "@/mixins/shared/base/settings/deleteSettingsMixin";
import { itemCount } from "@/utils";
import { colorTheme } from "@/design/colors/mdColors";

export default {
  name: "SearchFormField",
  mixins: [menuItemSettingsMixin, deleteSettingsMixin],
  components: {
    DatePicker: () => import("@/components/shared/core/pickers/DatePicker"),
    RemoveFieldButton: () => import("@/components/field/RemoveFieldButton"),
    IncludeFieldButton: () => import("@/components/field/IncludeFieldButton"),
    BaseTooltipIcon: () => import("@/components/shared/base/BaseTooltipIcon")
  },
  data() {
    return {
      dateFieldValue: "value",
      fieldType: fieldType,
      selectedOperatorIndex: 0,

      iconQuestion: iconQuestion,
      iconDateOn: iconDateOn,
      iconEqual: iconEqual,
      iconGreaterThan: iconGreaterThan,
      iconGreaterThanOrEqual: iconGreaterThanOrEqual,
      iconLessThan: iconLessThan,
      iconLessThanOrEqual: iconLessThanOrEqual,
      iconAsterisk: iconAsterisk,
      iconTrash: iconTrash,
      iconMinus: iconMinus,
      iconCalendar: iconCalendar,
      iconCheckbox: iconCheckbox,
      iconCheckboxBlank: iconCheckboxBlank,
      iconWarning: iconWarning,

      // Lookup
      isLoadingLookup: false,
      lookupError: undefined,
      iconColor: colorTheme.warning
    };
  },
  props: {
    /**
     * Form Field
     * @type {{id:number, name:string, label:string, fieldDataType:number, value: *, operators:[{operator:string, description:string}]}}
     */
    field: undefined,
    /**
     * Is removable field
     */
    removable: {
      type: Boolean,
      default: false
    },
    visibleInclude: {
      type: Boolean,
      default: true
    },
    disabledInclude: {
      type: Boolean,
      default: false
    },
    /**
     * determines whether Wildcard search is supported
     */
    disabledWildcard: {
      type: Boolean,
      default: false
    },
    disabledUniversalSearch: {
      type: Boolean,
      default: false
    },
    disabledUniversalSearchText: {
      type: String,
      default: undefined
    }
  },
  computed: {
    showCheckBox() {
      return this.field ? this.field.fieldDataType === fieldType.BIT : false;
    },
    showTextBox() {
      if (this.showLookup) {
        return false;
      }

      return this.field
        ? this.field.fieldDataType === fieldType.LITERAL ||
            this.field.fieldDataType === fieldType.CURRENCY ||
            this.field.fieldDataType === fieldType.NUMBER ||
            this.field.fieldDataType === fieldType.INTEGER
        : false;
    },
    showTextarea() {
      return this.field ? this.field.fieldDataType === fieldType.TEXT : false;
    },
    showDatePicker() {
      return this.field
        ? (this.field?.fieldDataType ?? fieldType.LITERAL) === fieldType.DATE
        : false;
    },
    isFieldNumeric() {
      const type = this.field?.fieldDataType ?? fieldType.LITERAL;

      return (
        type === fieldType.NUMBER ||
        type === fieldType.INTEGER ||
        type === fieldType.CURRENCY
      );
    },
    textFieldType() {
      return this.isFieldNumeric ? "number" : "text";
    },
    fieldLabel() {
      return this.field?.label ?? "Field Name";
    },
    fieldValue: {
      get() {
        return this.field?.value;
      },
      set(value) {
        if (this.field) {
          this.field.value = value;
        }
      }
    },
    fieldOperator: {
      get() {
        return this.field?.operator;
      },
      set(value) {
        if (this.field) {
          this.field.operator = value;
        }
      }
    },
    fieldInclude: {
      get() {
        return this.field?.include ?? false;
      },
      set(value) {
        if (this.field) {
          this.field.include = value;
        }
      }
    },
    /**
     * @return [{id:string, operator:string, description:string}]
     **/
    operators() {
      const operators = this.field?.operators ?? [];
      return this.disabledWildcard
        ? operators.filter(el => el.operator !== "*")
        : operators;
    },
    selectedOperatorIcon() {
      return this.iconOperator(this.fieldOperator);
    },

    lookupItems() {
      return lookupItems(this.field);
    },

    lookupItemCount() {
      return lookupItemCount(this.field);
    },

    lookupEnforce() {
      return this.readonly ? false : enforceLookup(this.computedFormField);
    },

    showLookup() {
      return this.lookupItemCount > 0;
    },

    visibleWarnIcon() {
      return this.field.name === "Name";
    }
  },
  methods: {
    iconOperator(operator) {
      switch (operator) {
        case searchOperators[searchOperator.greaterThan].operator:
          return this.iconGreaterThan;
        case searchOperators[searchOperator.equalOrGreaterThan].operator:
          return this.iconGreaterThanOrEqual;
        case searchOperators[searchOperator.lessThan].operator:
          return this.iconLessThan;
        case searchOperators[searchOperator.equalOrLessThan].operator:
          return this.iconLessThanOrEqual;
        case searchOperators[searchOperator.like].operator:
          return this.iconAsterisk;
        case searchOperators[searchOperator.contains].operator:
          return this.iconAsterisk;
        case searchOperators[searchOperator.equal].operator:
          return this.iconEqual;
        case searchOperators[searchOperator.on].operator:
          return this.iconDateOn;
        default:
          return this.iconQuestion;
      }
    },
    removeField() {
      this.$emit("trash");
    },
    onInclude() {
      this.fieldInclude = !this.fieldInclude;
    }
  },
  created() {
    if (!this.fieldOperator) {
      console.warn(`${this.field} this.fieldOperator`, this.fieldOperator);
    }

    const operator = this.operators.find(
      el => el.operator === this.fieldOperator
    );

    if (!operator) {
      console.warn(
        `Operator not found by fieldOperator: '${this.fieldOperator}' field:`,
        this.field
      );
      if (this.operators.length > 0) {
        this.fieldOperator = this.operators[0].operator;
        console.warn(`Changed fieldOperator:`, this.fieldOperator);
        this.$emit("operatorChanged");
      }
    }

    // Set selected Operator Index
    this.selectedOperatorIndex = operator
      ? this.operators?.indexOf(operator) ?? 0
      : 0;
  },
  watch: {
    selectedOperatorIndex(index) {
      try {
        const count = itemCount(this.operators);

        if (index >= 0 && index < count) {
          this.fieldOperator = this.operators[index].operator;
          console.log(
            `watch selectedOperatorIndex changed this.fieldOperator:`,
            this.fieldOperator
          );
          this.$emit("operatorChanged");
        } else {
          console.warn(
            `Not valid Operator Index: ${index}; operators count: ${count}`
          );
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
