import { ruleNames } from "@/model/rules/ruleModel";
import { isNumericFieldType } from "@/model/field/fieldModel";

/**
 * formField rules (conditions) in which the input is valid or invalid
 * @param {{id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[], operations: {name:string, allowed:boolean, valid:boolean}[], lookup: {databaseLookup:boolean, enforce:boolean, items: string[]}}} formField
 * @return {*[]} mixed array of types function, boolean and string
 */
const formFieldRules = formField => {
  const rules = [];

  if (formField?.requiredForAutoFiling ?? false) {
    rules.push(value => !!value || ruleNames.requiredByAutoFiling);
  }

  if (formField?.isRequired ?? false) {
    rules.push(value => !!value || ruleNames.required);
  }

  return rules;
};

/**
 * get lookup Item Count
 * @param {{id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[], operations: {name:string, allowed:boolean, valid:boolean}[], lookup: {databaseLookup:boolean, enforce:boolean, items: string[]}}} formField
 * @return {number} lookup Item Count
 */
const lookupItemCount = formField => formField?.lookup?.items?.length ?? 0;

/**
 * lookup Items
 * @param {{id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[], operations: {name:string, allowed:boolean, valid:boolean}[], lookup: {databaseLookup:boolean, enforce:boolean, items: string[]}}} formField
 * @return {number|string[]}
 */
const lookupItems = formField => {
  if (lookupItemCount(formField) === 0) {
    return [];
  }

  if (isNumericFieldType(formField?.fieldDataType ?? -1)) {
    return formField?.lookup?.items?.map(el => Number(el)) ?? [];
  }

  return formField?.lookup?.items ?? [];
};

/**
 * get lookup Type Name
 * @param {{id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[], operations: {name:string, allowed:boolean, valid:boolean}[], lookup: {databaseLookup:boolean, enforce:boolean, items: string[]}}} formField
 * @return {string} returns lookup Type Name
 */
const lookupTypeName = formField =>
  formField?.lookup?.databaseLookup ?? false ? "Database Lookup" : "Lookup";

/**
 * enforce Lookup
 * @param {{id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[], operations: {name:string, allowed:boolean, valid:boolean}[], lookup: {databaseLookup:boolean, enforce:boolean, items: string[]}}} formField
 * @return {boolean}
 */
const enforceLookup = formField => formField?.lookup?.enforce ?? false;

export {
  formFieldRules,
  lookupItemCount,
  lookupItems,
  lookupTypeName,
  enforceLookup
};
